<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors, ...errorsPost]"
                :question="question"
                :valid="data !== null">

    <template v-for="(recipient, i) in data">
      <div :key="i"
           class="d-flex justify-content-start align-items-center">
        <span class="w-33 pt-20 pr-12 text-right">{{ cardTitle(recipient) }}</span>
        <InputBasic v-model="data[i].share"
                    :args="args"
                    :db="{
                saveLocation: 'product_will_data',
                formPath: `data.${location}.details.gifts.${index}.${shareLocation}.${i}.share`,
                jsonSaveField: 'share'
              }"
                    :formatter="formatPercentage"
                    :noSave="true"
                    :skipped="skipped"
                    @jsErrors="jsErrors=$event"
                    @serverErrors="serverErrors=$event"
                    @skipped="skipped=$event" />
      </div>
    </template>
  </QuestionBase>
</template>

<script>
import {willPeopleHelpers} from '../../../../../fieldsets/will/helpers/willPeopleHelpers';
import {questionLoadHelpers} from '../../../../helpers/questionLoadHelpers';
import {willOptions} from '../../../../options/willOptions';
import {personHelpers} from '@/mixins/personHelpers';
import {textHelpers} from '@/mixins/textHelpers';
import {inputFormatters} from "@/mixins/inputFormatters";

import QuestionBase from '../../../../QuestionBase';
import InputBasic from '../../../../inputs/InputBasic';


export default {
  name: 'ShareSeparatePercentages',
  mixins: [
    questionLoadHelpers,
    willPeopleHelpers,
    personHelpers,
    textHelpers,
    willOptions,
    inputFormatters
  ],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: [Array],
      required: false
    },
    noSave: {
      // no save set to true: saving is done through a watcher in the recipients.vue file
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    location: {
      type: String,
      required: true
    },
    shareLocation: {
      type: String,
      required: true
    },
    giftDetails: {
      type: Object,
      required: true
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `In what shares would you like ${this.listPeopleAddressDob({
          people: this.data,
          noDob: true,
          firstName: true
        })} to receive ${this.giftDetails.amount ? 'the £' + this.giftDetails.amount : 'the gift'}?`,
        subTitle: null,
        tip: null,
      }
    },
  },
  data() {
    return {
      // no save set to true: saving is done through a watcher in the recipients.vue file
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}ShareSeparatePercentages`,
        formPath: '',
        jsonSaveField: ''
      },
      args: {
        placeholder: 'e.g. 40',
        label: null,
        type: 'text',
        skippable: false,
        required: false,
        inputClass: 'field-66',
        appendText: '%'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
  methods: {
    cardTitle(person) {
      if ([
        'professional',
        'group',
        'charity'
      ].includes(person.type)) return this.capitalise(person.name)
      return `${this.fullName(person,
          false,
          false,
          false,
          true)}`
    }
  }
}
</script>
