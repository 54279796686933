<template>
  <QuestionBase :id="db.saveField"

                :errors="[...jsErrors, ...errorsPost]"
                :question="question"
                :sub-section="true"
                :valid="!!data.length">

    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard v-for="(person, i) in data"
                    :id="db.saveField + i"
                    :key="db.saveField + i"
                    :ref="db.saveField + i"
                    :index="i"
                    :select-mode="true"
                    :selected="true"
                    :show-delete="false"
                    :title="cardTitle(person)"
                    :value="data[i]"
                    type="recipientsData"
                    :loading="loading"
                    @click="deselectConfirm(person, 'Remove person from gift backup recipients?', index && index >= 0)"
                    @delete="deletePerson(person.id)"
                    @save="savePerson( null, db.saveField + i)">

          <PersonWill v-if="person.type === 'person'"
                      :key="'recipients-form-data' + i"
                      v-model="data[i]"
                      :errors-post="errorsPatch"
                      :no-save="true"
                      :objectId="person.id"
                      @save="savePersonForm($event, person)" />

          <GroupWill v-if="person.type === 'group'"
                     :key="'recipients-form-data' + i"
                     v-model="data[i]"
                     @save="savePersonForm($event, person)" />

          <CharityWill v-if="person.type === 'charity'"
                       :key="'recipients-form-data' + i"
                       v-model="data[i]"
                       @save="savePersonForm($event, person)" />

        </ObjectCard>
      </transition-group>
    </div>
    <!-- New People -->
    <transition name="fade">
      <b-button class="btn-question w-100"
                @click="show.addPerson=true">
        <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Substitute Recipient
      </b-button>
    </transition>

    <WillPeopleModal v-model="show.addPerson"
                     :dataSelected="data"
                     :noSave="noSave"
                     :options="optionsData"
                     :show-charities="true"
                     :show-groups="true"
                     title="Add Backup Recipient"
                     @dataSelectedUpdate="data=$event"
                     @save="save" />

  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../../QuestionBase";
import ObjectCard from "../../../objects/ObjectCard";
import PersonWill from "../../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../../helpers/WillPeopleModal";
import GroupWill from "../../../../../fieldsets/sub/people/GroupWill";
import CharityWill from "../../../../../fieldsets/sub/people/CharityWill";

export default {
  name: 'RecipientsBackup',
  components: {
    CharityWill,
    GroupWill,
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    optionsData: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    location: {
      type: String,
      required: true
    },
    noSave: {
      type: Boolean,
      default: false
    },
    giftDetails: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    'giftDetails.recipients': {
      deep: true,
      immediate: false,
      handler(value) {
        // if primary recipients includes a charity then clear backup recipients
        if (value.filter(recipient => recipient.type === 'charity').length) {
          this.data = []
        }
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}BackupRecipients`,
        formPath: `data.${this.location}.details.gifts.${this.index}.backupRecipients`,
        jsonSaveField: 'backupRecipients',
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Backup Executor, click to select'
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `Substitute Recipients`,
        subTitle: `The person / people named here will inherit if ${this.listPeopleAddressDob({
          people: this.giftDetails.recipients,
          noDob: true,
          firstName: true
        })} ${this.isAre({list: this.giftDetails.recipients})} unable to benefit.`,
        tip: null,
      }
    },
  }
}
</script>
