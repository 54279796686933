<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Gifts 'Direct'</h3>

    <FirstGiftsYN
        v-model="form.data.giftsFirst.details.giftsYN"
        :callback-function="resetFirstGifts"/>

    <template v-if="detailsFirstGifts.giftsYN">

      <template v-if="form.data.giftsFirst.details.gifts.length">
        <GiftObjects
            v-model="form.data.giftsFirst.details.gifts"
            location="giftsFirst"/>
        <AdditionalFirstGiftsYN
            ref="firstAdditionalGifts"
            v-model="form.data.giftsFirst.details.additionalGiftYN"/>
      </template>

      <GiftNew
          v-if="detailsFirstGifts.additionalGiftYN || !detailsFirstGifts.gifts.length "
          v-model="form.data.giftsFirst.details.gifts"
          :existingGifts="detailsFirstGifts.gifts"
          location="giftsFirst"
          @clearAdditionalGiftYN="setNewGiftBlank('giftsFirst')"/>
    </template>

    <h3
        v-if="form.data.distributionPrimary.details.partnerPrimaryYN || form.data.giftsSecond.details.gifts.length"
        class="fieldset-heading">Gifts 'Second Death'</h3>

    <SecondGiftsYN
        v-if="form.data.distributionPrimary.details.partnerPrimaryYN || form.data.giftsSecond.details.gifts.length"
        v-model="form.data.giftsSecond.details.giftsYN"
        :callback-function="resetSecondGifts"/>

    <template
        v-if="detailsSecondGifts.giftsYN">

      <template
          v-if="form.data.giftsSecond.details.gifts.length">
        <GiftObjects
            v-model="form.data.giftsSecond.details.gifts"
            location="giftsSecond"/>
        <AdditionalSecondGiftsYN
            ref="secondAdditionalGifts"
            v-model="form.data.giftsSecond.details.additionalGiftYN"/>
      </template>

      <GiftNew
          v-if="detailsSecondGifts.additionalGiftYN || !detailsSecondGifts.gifts.length "
          v-model="form.data.giftsSecond.details.gifts"
          :existingGifts="detailsSecondGifts.gifts"
          location="giftsSecond"
          @clearAdditionalGiftYN="setNewGiftBlank('giftsSecond')"/>
    </template>

  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willGiftsHelpers} from "./helpers/willGiftsHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import FirstGiftsYN from "../../question/questions/will/gifts/FirstGiftsYN";
import SecondGiftsYN from "../../question/questions/will/gifts/SecondGiftsYN";
import GiftObjects from "../../question/questions/will/gifts/GiftObjects";
import GiftNew from "../../question/questions/will/gifts/GiftNew";
import AdditionalFirstGiftsYN from "../../question/questions/will/gifts/AdditionalFirstGiftsYN";
import AdditionalSecondGiftsYN from "../../question/questions/will/gifts/AdditionalSecondGiftsYN";

export default {
  name: 'Gifts',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willGiftsHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AdditionalSecondGiftsYN,
    AdditionalFirstGiftsYN,
    GiftNew,
    GiftObjects,
    SecondGiftsYN,
    FirstGiftsYN
  },
  data() {
    return {
      label: 'Gifts', // used for steps progress
      dbFirst: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.giftsFirst.details'
      },
      dbSecond: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.giftsSecond.details'
      },
      additionalFirstGiftYN: null,
      additionalSecondGiftYN: null
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
