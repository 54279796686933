<template>
  <QuestionBase :question="question" :errors="[...jsErrors]" :valid="!!giftsMainInput && !!data" :id="db.saveField">

    <InputRadio
        v-model="giftsMainInput"
        @input="setForm"
        :db="db"
        :no-save="true"
        :options="giftsMainOptions"
        class="radio-options-wide"
    />
    <div
        v-if="form"
        class="question-box with-arrow mt-32">
      <transition name="fade">
        <div class="accordion sub-items">
          <ObjectCard
              v-model="form"
              ref="gift-add"
              type="gift-add"
              :index="null"
              title="New Gift"
              :loading="loading"
              @delete="removeForm"
              @save="saveGift(form, null, `${location}null`, giftsMainInput)"
          >
            <GiftCash
                v-if="giftsMainInput === 'cash'"
                v-model="form"
                :noSave="true"
                :errors-post="errorsPost"
                :index="null"
                :location="location"
            />
            <GiftProperty
                v-else-if="giftsMainInput === 'property'"
                v-model="form"
                :noSave="true"
                :errors-post="errorsPost"
                :index="null"
                :location="location"
            />
            <GiftJewellery
                v-else-if="giftsMainInput === 'jewellery'"
                v-model="form"
                :noSave="true"
                :errors-post="errorsPost"
                :index="null"
                :location="location"
            />
            <GiftAccount
                v-else-if="giftsMainInput === 'account'"
                v-model="form"
                :noSave="true"
                :errors-post="errorsPost"
                :index="null"
                :location="location"
            />
            <GiftVehicle
                v-else-if="giftsMainInput === 'vehicle'"
                v-model="form"
                :noSave="true"
                :errors-post="errorsPost"
                :index="null"
                :location="location"
            />
            <GiftOther
                v-else-if="giftsMainInput === 'other'"
                v-model="form"
                :noSave="true"
                :errors-post="errorsPost"
                :index="null"
                :location="location"
            />
          </ObjectCard>
        </div>
      </transition>
    </div>

  </QuestionBase>
</template>

<script>
import {assetOptions} from "../../../options/assetOptions";
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {giftSaveHelpers} from "./giftSaveHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {general} from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import GiftCash from "../../../../fieldsets/sub/gifts/Cash";
import InputRadio from "../../../inputs/InputRadio";
import GiftProperty from "../../../../fieldsets/sub/gifts/Property";
import GiftJewellery from "../../../../fieldsets/sub/gifts/Jewellery";
import GiftAccount from "../../../../fieldsets/sub/gifts/Account";
import GiftVehicle from "../../../../fieldsets/sub/gifts/Vehicle";
import GiftOther from "../../../../fieldsets/sub/gifts/Other";


export default {
  name: 'GiftNew',
  components: {
    GiftOther,
    GiftVehicle, GiftAccount, GiftJewellery, GiftProperty, InputRadio, GiftCash, ObjectCard, QuestionBase
  },
  mixins: [objectSaveHelpers, questionLoadHelpers, assetOptions, blankForms, general, giftSaveHelpers],
  props: {
    value: {
      type: Array,
      required: false
    },
    existingGifts: {
      // used for scrolling to index of added gift
      type: Array,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.setGiftRadioInput()
  },
  data() {
    return {
      question: {
        title: 'What would you like to gift?',
        subTitle: 'You can gift almost anything that you own. Note that you cannot gift assets held on trust. Also, gifts of jointly owned assets will pass to the surviving joint owner unless the other joint owners pass away before you.',
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}New`,
        formPath: `data.${this.location}.details.gifts`,
        jsonSaveField: 'gifts',
        validationField: 'giftsMainInput', // used for navigation
        objectValidationField: 'fake field' // used for progress
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessageNewOpenForm: "Save gift before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      giftsMainInput: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    setForm(giftType) {
      if (giftType === 'cash') {
        this.form = this.clone(this.cashGiftBlankForm)
        // change prevent gifts paying twice if cash gift dependent on first or second death
        this.form.preventGiftPayingTwice = this.location !== 'giftsFirst'
      }
      else if (giftType === 'property') this.form = this.clone(this.propertyGiftBlankForm)
      else if (giftType === 'jewellery') this.form = this.clone(this.jewelleryGiftBlankForm)
      else if (giftType === 'account') this.form = this.clone(this.accountGiftBlankFrom)
      else if (giftType === 'vehicle') this.form = this.clone(this.vehicleGiftBlankForm)
      else if (giftType === 'other') this.form = this.clone(this.otherGiftBlankForm)
    },
    removeForm() {
      this.giftsMainInput = null
      this.form = null
      this.errorsPost = {}
    },
    setGiftRadioInput() {
      for (let i = 0; i < this.assetTypeOptions.length; i++) {
        if (this.data.find(asset => asset.assetType === this.assetTypeOptions[i].value)) {
          this.giftsMainInput.push(this.assetTypeOptions[i].value)
        }
      }
    }
  }
}
</script>
