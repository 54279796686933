<template>
  <QuestionBase
      :showQuestion="false"
      :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
      :valid="!!data"
      :disabled-valid="true"
  >
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {personHelpers} from "@/mixins/personHelpers";

import QuestionBase from "../../../../QuestionBase";

export default {
  name: 'JewelleryText',
  mixins: [questionLoadHelpers, saveHelpers, personHelpers],
  components: {
    QuestionBase
  },
  props: {
    value: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
      required: false
    },
    giftDetails: {
      type: Object,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Text?',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}CashText`,
        formPath: `data.${this.location}.details.gifts.${this.index}.text`,
        jsonSaveField: 'text'
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
  methods: {

    setText() {
      this.$nextTick(() => {
        if (this.giftDetails.jewelleryType === 'all') {
          this.data = 'all my jewellery'
        } else if (this.giftDetails.jewelleryType === 'choice') {
          this.data =  'a choice of my jewellery not otherwise specifically gifted by my Will or any Codicil to be ' +
              'selected within three months of my death'
        } else if (this.giftDetails.jewelleryType === 'custom') {
          this.data = this.giftDetails.userCustomText
        }
      })
    }

  }
}
</script>