<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors, ...errorsPost]" :valid="!!data">
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :no-save="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../../QuestionBase";
import InputBasic from "../../../../inputs/InputBasic";

export default {
  name: 'AccountNumber',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What is the account number?',
        subTitle:  null,
        tip: null
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}AccountNumber`,
        formPath: `data.${this.location}.details.gifts.${this.index}.accountNumber`,
        jsonSaveField: 'accountNumber'
      },
      args: {
        placeholder: 'e.g. 567900',
        label: null,
        skippable: false,
        required: false,
        inputClass: 'field-66'

      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>