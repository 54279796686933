<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors, ...errorsPost]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :no-save="noSave"
                :options="booleanYesNo"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {general} from "../../../../options/general";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'StirpesYN',
  mixins: [
    general,
    questionLoadHelpers,
    willPeopleHelpers,
    willRelationshipHelpers,
    personHelpers,
    textHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    location: {
      type: String,
      required: true
    },
    giftDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    recipientsContainsGroup() {
      return !!(this.giftDetails.recipients.filter(option => option.type === 'group').length)
    },
    question() {
      return {
        title: this.giftDetails.recipients.length ? (this.recipientsContainsGroup ? `If any of the recipients pass away before you, should the gift instead pass to their children?` : `If ${this.listPeopleAddressDob({
          people: this.giftDetails.recipients,
          andOr: ' or ',
          space: ' or ',
          noDob: true,
          firstName: true
        })} ${this.hasHave({list: this.giftDetails.recipients})} passed away before you, should ${this.giftDetails.recipients.length > 1 ? 'their' : this.hisHer({list: this.giftDetails.recipients})} share instead pass to ${this.giftDetails.recipients.length > 1 ? 'their' : this.hisHer({list: this.giftDetails.recipients})} children?`) : 'Should a recipients share pass to their children if they pass before you?',
        subTitle: 'Even though the recipient may not have children now, consider what you would want should they have children in the future.',
        tip: null,
      }
    },
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}Stirpes`,
        formPath: `data.${this.location}.details.gifts.${this.index}.stirpes`,
        jsonSaveField: 'stirpes',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
