<template>
  <QuestionBase
      :showQuestion="false"
      :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
      :valid="!!data"
      :disabled-valid="true"
  >
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {personHelpers} from "@/mixins/personHelpers";

import QuestionBase from "../../../../QuestionBase";

export default {
  name: 'AccountText',
  mixins: [questionLoadHelpers, saveHelpers, personHelpers],
  components: {
    QuestionBase
  },
  props: {
    value: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
      required: false
    },
    giftDetails: {
      type: Object,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Text?',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}CashText`,
        formPath: `data.${this.location}.details.gifts.${this.index}.text`,
        jsonSaveField: 'text'
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
  methods: {

    setText() {
      this.$nextTick(() => {
        if (this.giftDetails.accountType === 'allBank') {
          this.data = 'all monies standing to my credit in all my bank and building society accounts ' +
              'together with all interest accrued due and accruing thereon at my death'
        } else if (this.giftDetails.accountType === 'specificBank') {
          this.data = `all monies standing to my credit in account number
          ${this.giftDetails.accountNumber} with ${this.giftDetails.accountProvider}
          together with all interest accrued due and accruing thereon at my death`
        } else if (this.giftDetails.accountType === 'allIsa') {
          this.data = 'all the assets comprised in the subsisting Individual Savings Accounts (whether Maxi or Mini in ' +
              'nature) of which I am at the date of my death the beneficial owner including without prejudice to ' +
              'the generality of the above all stocks and shares (encompassing unit trusts) the cash balances held ' +
              'in deposit accounts and cash unit trusts and life assurance policy proceeds (encompassing bonds) and ' +
              'the aggregate of residual cash balances (including those derived from tax refunds) held within the ' +
              'Accounts at the date of my death and I direct that the cost of giving effect to this gift shall be ' +
              'borne by the legatee'
        } else if (this.giftDetails.accountType === 'stockShare') {
          this.data = `all shares beneficially owned by me in ${this.giftDetails.accountProvider} and or including any shares or ` +
              'shareholding derived therefrom together with all dividends due and payable'
        } else if (this.giftDetails.accountType === 'miniIsa') {
          this.data = 'all the assets comprised in the subsisting Stocks and Shares MINI Individual Savings Account ' +
              `managed by ${this.giftDetails.accountProvider} of which I am at the date of my death the beneficial owner including without ` +
              'prejudice to the generality of the above all stocks and shares (encompassing unit trusts) and any ' +
              'residual cash balances (including those derived from tax refunds) held within the Account at the date ' +
              'of my death and I direct that the cost of giving effect to this gift shall be borne by the legatee'
        } else if (this.giftDetails.accountType === 'cashIsa') {
          this.data = 'all the assets comprised in the subsisting Cash MINI Individual Savings Account managed ' +
              `by ${this.giftDetails.accountProvider} of which I am at the date of my death the beneficial owner including without ` +
              'prejudice to the generality of the above all cash balances held in deposit accounts and cash ' +
              'unit trusts and any residual cash balances (including those derived from tax refunds) held within ' +
              'the Account at the date of my death and I direct that the cost of giving effect to this gift shall ' +
              'be borne by the legatee'
        } else if (this.giftDetails.accountType === 'maxiIsa') {
          this.data = 'all the assets comprised in the subsisting MAXI Individual Savings Account managed ' +
              `by ${this.giftDetails.accountProvider} of which I am at the date of my death the beneficial owner including without` +
              'prejudice to the generality of the above all stocks and shares (encompassing unit trusts) the cash ' +
              'balances held in deposit accounts and cash unit trusts and life assurance policy proceeds ' +
              '(encompassing bonds) and the aggregate of residual cash balances (including those derived from tax ' +
              'refunds) held within the Accounts at the date of my death and I direct that the cost of giving effect ' +
              'to this gift shall be borne by the legatee'
        } else if (this.giftDetails.accountType === 'custom') {
          this.data = this.giftDetails.userCustomText
        }
      })
    }

  }
}
</script>