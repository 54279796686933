<template>
  <div>
    <div class="question-box-intro-two"><h3>Details</h3></div>

    <VehicleText
        v-model="form.text"
        :index="index"
        :noSave="noSave"
        :errorsPost="errorsPost.text"
        :giftDetails="form"
        :location="location"
        ref="vehicleText"
        :id="`${location}${index}text`"
    />

    <VehicleType
        v-model="form.vehicleType"
        @input="$refs.vehicleText.setText()"
        :errors-post="errorsPost.vehicleType"
        :index="index"
        :no-save="noSave"
        :form="form"
        :location="location"
        :id="`${location}${index}vehicleType`"
    />

    <VehicleName
        v-if="showVehicleName"
        @input="$refs.vehicleText.setText()"
        v-model="form.vehicleName"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.vehicleName"
        :location="location"
        :id="`${location}${index}vehicleName`"
    />

    <VehicleMake
        v-if="showVehicleDetails"
        @input="$refs.vehicleText.setText()"
        v-model="form.vehicleMake"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.vehicleMake"
        :location="location"
        :id="`${location}${index}vehicleMake`"
    />

    <VehicleModel
        v-if="showVehicleDetails"
        @input="$refs.vehicleText.setText()"
        v-model="form.vehicleModel"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.vehicleModel"
        :location="location"
        :id="`${location}${index}vehicleModel`"
    />

    <CustomText
        v-if="form.vehicleType === 'custom'"
        @input="$refs.vehicleText.setText()"
        v-model="form.userCustomText"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.userCustomText"
        :location="location"
        :id="`${location}${index}userCustomText`"
    />

    <TaxYN
        v-model="form.taxFree"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.taxFree"
        :location="location"
        :id="`${location}${index}taxFree`"
    />

    <ConditionYN
        v-model="form.conditionYN"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.conditionYN"
        :location="location"
        :id="`${location}${index}conditionYN`"
    />

    <Condition
        v-if="form.conditionYN"
        v-model="form.condition"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.condition"
        :location="location"
        :id="`${location}${index}condition`"
    />

    <Recipients
        v-model="form.recipients"
        :options-data="primaryOptions"
        :index="index"
        :no-save="noSave"
        :location="location"
        :errors-post="errorsPost.recipients"
        :id="`${location}${index}recipients`"
    />

    <ShareType
        v-if="recipientsMoreThanOne"
        v-model="form.shareType"
        :index="index"
        :no-save="noSave"
        :location="location"
        :gift-details="form"
        :eachOption="false"
        type-location="shareType"
        :id="`${location}${index}shareType`"
    />

    <ShareSeparatePercentages
        v-if="form.shareType === 'separate'"
        v-model="form.recipients"
        :index="index"
        :no-save="noSave"
        :location="location"
        :gift-details="form"
        shareLocation="recipients"
    />

    <div v-if="showPerStirpesOptions">
      <StirpesYN
          v-model="form.stirpes"
          :index="index"
          :no-save="noSave"
          :errors-post="errorsPost.stirpes"
          :location="location"
          :gift-details="form"
          :id="`${location}${index}stirpes`"
      />
      <StirpesAge
          v-if="form.stirpes"
          v-model="form.stirpesAge"
          :index="index"
          :no-save="noSave"
          :errors-post="errorsPost.stirpesAge"
          :location="location"
          :id="`${location}${index}stirpesAge`"
      />
    </div>

    <div v-show="showBackupOptions">
      <RecipientsBackupYN
          v-model="form.backupRecipientsYN"
          :index="index"
          :no-save="noSave"
          :errors-post="errorsPost.backupRecipientsYN"
          :location="location"
          :gift-details="form"
          :id="`${location}${index}backupRecipientsYN`"
      />

      <RecipientsBackup
          v-if="form.backupRecipientsYN"
          v-model="form.backupRecipients"
          :options-data="secondaryOptions"
          :index="index"
          :no-save="noSave"
          :location="location"
          :gift-details="form"
          :errors-post="errorsPost.backupRecipients"
          :id="`${location}${index}backupRecipients`"
      />

      <ShareType
          v-if="backupRecipientsMoreThanOne"
          v-model="form.shareTypeBackup"
          :index="index"
          :no-save="noSave"
          :location="location"
          :gift-details="form"
          :eachOption="false"
          type-location="shareTypeBackup"
          :id="`${location}${index}shareTypeBackup`"
      />

      <ShareSeparatePercentages
          v-if="form.shareTypeBackup === 'separate'"
          v-model="form.backupRecipients"
          :index="index"
          :no-save="noSave"
          :location="location"
          :gift-details="form"
          shareLocation="backupRecipients"
      />
    </div>
  </div>
</template>

<script>
import {giftRecipientHelpers} from "../../../question/questions/will/gifts/giftRecipientHelpers";

import ConditionYN from "../../../question/questions/will/gifts/sub/ConditionYN";
import Condition from "../../../question/questions/will/gifts/sub/Condition";
import TaxYN from "../../../question/questions/will/gifts/sub/TaxYN";
import StirpesYN from "../../../question/questions/will/gifts/sub/StirpesYN";
import StirpesAge from "../../../question/questions/will/gifts/sub/StirpesAge";
import Recipients from "../../../question/questions/will/gifts/sub/Recipients";
import RecipientsBackup from "../../../question/questions/will/gifts/sub/RecipientsBackup";
import RecipientsBackupYN from "../../../question/questions/will/gifts/sub/RecipientsBackupYN";
import ShareType from "../../../question/questions/will/gifts/sub/ShareType";
import ShareSeparatePercentages from "../../../question/questions/will/gifts/sub/ShareSeparatePercentages";
import CustomText from "../../../question/questions/will/gifts/sub/CustomText";
import VehicleText from "../../../question/questions/will/gifts/sub/VehicleText";
import VehicleType from "../../../question/questions/will/gifts/sub/VehicleType";
import VehicleName from "../../../question/questions/will/gifts/sub/VehicleName";
import VehicleMake from "../../../question/questions/will/gifts/sub/VehicleMake";
import VehicleModel from "../../../question/questions/will/gifts/sub/VehicleModel";

export default {
  name: 'GiftVehicle',
  components: {
    VehicleModel,
    VehicleMake,
    VehicleName,
    VehicleType,
    VehicleText,
    CustomText,
    ShareSeparatePercentages,
    ShareType,
    RecipientsBackupYN,
    RecipientsBackup,
    Recipients,
    StirpesAge,
    StirpesYN,
    TaxYN,
    Condition,
    ConditionYN
  },
  mixins: [giftRecipientHelpers],
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    showVehicleDetails() {
      return ['car', 'caravan', 'boat'].includes(this.form.vehicleType)
    },
    showVehicleName() {
      return ['boat'].includes(this.form.vehicleType)
    }
  }
}
</script>