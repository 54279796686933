<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors, ...errorsPost]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :options="booleanYesNo"
                :skipped="skipped"
                :no-save="noSave"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {general} from "../../../../options/general";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'PropertyHasMortgageYN',
  mixins: [
    general,
    questionLoadHelpers,
    willPeopleHelpers,
    willRelationshipHelpers,
    personHelpers,
    textHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Is there a mortgage on the property?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}HasMortgage`,
        formPath: `data.${this.location}.details.gifts.${this.index}.hasMortgage`,
        jsonSaveField: 'hasMortgage',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
