<template>
  <QuestionBase
      :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
      :valid="!!data"
  >
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :noSave="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../../QuestionBase";
import InputBasic from "../../../../inputs/InputBasic";

export default {
  name: 'CashAmount',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
      required: false
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'How much money would you like to gift?',
        subTitle: 'Avoid gifting large sums of money particularly where your financial situation could change in the future.',
        tip: null
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}CashAmount`,
        formPath: `data.${this.location}.details.gifts.${this.index}.amount`,
        jsonSaveField: 'amount',
      },
      args: {
        placeholder: 'e.g. 6000',
        label: null,
        type: 'number',
        skippable: false,
        required: false,
        inputClass: 'field-66',
        prependText: '£'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>