<template>
  <InputAddress
      v-model="data"
      :db-override="db"
      :question-override="question"
      :jsErrorsData="jsErrors"
      :noSave="true"
      :errors-post-data="errorsPost"
      @jsErrors="jsErrors=$event"
  />
</template>

<script>
import InputAddress from "../../../../inputs/InputAddress";

export default {
  name: 'PropertyAddress',
  components: {InputAddress},
  props: {
    value: {
      type: Object,
      required: false
    },
    form: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What address of the property?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}Address`,
        formPath: `data.${this.location}.details.gifts.${this.index}.address`,
        jsonSaveField: 'address',
        deleteLocation: 'address'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show
    }
  }
}
</script>