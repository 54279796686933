<template>
  <QuestionBase
      :question="question"
      :errors="[...jsErrors]"
      :valid="!!data.length"
      :id="db.saveField"
  >
    <div class="question-box with-arrow mt-32">
      <transition-group name="fade">

        <template v-for="(gift, index) in data">
          <div :key="'gift' + index" :id="`${location}${index}`"
               class="accordion sub-items" >
            <ObjectCard
                v-model="data[index]"
                :ref="`${location}${index}`"
                type="gift"
                :loading="loading"
                :title="giftTitle(data[index])"
                :index="index"
                @save="saveGift(data[index], index, `${location}${index}`)"
                @delete="deleteGiftConfirm(index)"
            >
              <GiftCash
                  v-if="data[index].subType === 'cash'"
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  @saveGift="saveGift(data[index], index, null)"
              />

              <GiftProperty
                  v-else-if="data[index].subType === 'property'"
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  @saveGift="saveGift(data[index], index, null)"
              />

              <GiftJewellery
                  v-else-if="data[index].subType === 'chattel' && 'jewelleryType' in data[index]"
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  @saveGift="saveGift(data[index], index, null)"
              />
              <GiftAccount
                  v-else-if="data[index].subType === 'account'"
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  @saveGift="saveGift(data[index], index, null)"
              />
              <GiftVehicle
                  v-else-if="data[index].subType === 'vehicle'"
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  @saveGift="saveGift(data[index], index, null)"
              />

              <GiftOther
                  v-else
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  @saveGift="saveGift(data[index], index, null)"
              />

            </ObjectCard>
          </div>

        </template>
      </transition-group>
    </div>
  </QuestionBase>
</template>

<script>
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {assetOptions} from "../../../options/assetOptions";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {general} from "../../../options/general";
import {textHelpers} from "@/mixins/textHelpers";
import {giftSaveHelpers} from "./giftSaveHelpers";
import { personHelpers } from "@/mixins/personHelpers";
import { currencyFormat } from "@/mixins/currencyFormat";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import GiftCash from "../../../../fieldsets/sub/gifts/Cash";
import GiftProperty from "../../../../fieldsets/sub/gifts/Property";
import GiftJewellery from "../../../../fieldsets/sub/gifts/Jewellery";
import GiftAccount from "../../../../fieldsets/sub/gifts/Account";
import GiftVehicle from "../../../../fieldsets/sub/gifts/Vehicle";
import GiftOther from "../../../../fieldsets/sub/gifts/Other";

export default {
  name: 'GiftObjects',
  components: {
    GiftOther,
    GiftVehicle,
    GiftAccount,
    GiftJewellery,
    GiftProperty,
    GiftCash,
    ObjectCard,
    QuestionBase
  },
  mixins: [questionLoadHelpers, assetOptions, blankForms, general, textHelpers, giftSaveHelpers, saveHelpers, personHelpers, currencyFormat],
  props: {
    value: {
      type: Array,
      required: false
    },
    location: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.setGiftRadioInput()
  },
  data() {
    return {
      question: {
        title: 'What would you like to gift?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: this.location,
        formPath: `data.${this.location}.details.gifts`,
        jsonSaveField: 'gifts',
      },
      args: {
        skippable: false,
        required: false,
        customErrorMessageNewOpenForm: "Save gift before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      giftsMainInput: [],
      addGiftYN: null,
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    removeForm(value) {
      this.forms[value] = null
      this.errorsPost[value] = {}

      if (value === 'cash') {
        this.removeFromMainInput('cash')
      }
    },
    setGiftRadioInput() {
      for (let i = 0; i < this.assetTypeOptions.length; i++) {
        if (this.data.find(asset => asset.assetType === this.assetTypeOptions[i].value)) {
          this.giftsMainInput.push(this.assetTypeOptions[i].value)
        }
      }
    },
    removeFromMainInput(value) {
      let index = this.giftsMainInput.findIndex(option => option === value)
      if (index >= 0) this.giftsMainInput.splice(index, 1)
    },
    giftTitle(gift) {
      if (gift.subType === 'cash') return this.currency(gift.amount)
      if (gift.subType === 'property' && gift.address) return `${gift.address.line_1} ${gift.address.postcode}`
      if (gift.subType === 'property' && !gift.address) return 'Property'
      if (gift.subType === 'chattel' && 'jewelleryType' in gift) return 'Gift of Jewellery'
      if (gift.subType === 'account') return 'Financial Account'
      if (gift.subType === 'chattel' && 'vehicleType' in gift && gift.vehicleType === 'all') return 'All Vehicles'
      if (gift.subType === 'chattel' && 'vehicleType' in gift && gift.vehicleMake && gift.vehicleModel) return `${gift.vehicleMake} ${gift.vehicleModel}`
      return this.capitalise(gift.text)
    }
  }
}
</script>
