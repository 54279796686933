<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors, ...errorsPost]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :options="options"
                :skipped="skipped"
                :no-save="noSave"
                class="radio-options-wide"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import { willOptions } from "../../../../options/willOptions";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

import QuestionBase from "../../../../QuestionBase";
import InputRadio from "../../../../inputs/InputRadio";


export default {
  name: 'ShareType',
  mixins: [
    questionLoadHelpers,
    willPeopleHelpers,
    willRelationshipHelpers,
    personHelpers,
    textHelpers,
    willOptions
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    location: {
      type: String,
      required: true
    },
    giftDetails: {
      type: Object,
      required: true
    },
    typeLocation: {
      type: String,
      required: true
    },
    // display the following options props
    eachOption: {
      type: Boolean,
      default: true
    },
    equalOption: {
      type: Boolean,
      default: true
    },
    separateOption: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: this.giftDetails.amount ? `How would you like the £${this.giftDetails.amount} to be distributed amongst the recipients?` : `How would you like the gift to be distributed amongst the recipients?`,
        subTitle: `The ${this.giftDetails.amount ? '£' + this.giftDetails.amount : 'gift'} could be split between ${this.listPeopleAddressDob({
          people: this.giftDetails.recipients,
          noDob: true,
          firstName: true
        })} (equally or in specific shares) or each of them could receive the full ${this.giftDetails.amount ? '£' + this.giftDetails.amount : 'amount'}.`,
        tip: null,
      }
    },
    isSingleGroup () {
      return !!(this.giftDetails.recipients.length === 1 && this.giftDetails.recipients.filter(option => option.type === 'group').length)
    },
    options () {
      let options = []
      if (this.eachOption) options.push({
        label: 'To Each',
        value: 'each'
      })
      if (this.equalOption) options.push({
        label: 'Split Equally',
        value: 'equal'
      })
       if (this.separateOption && !this.isSingleGroup) options.push({
         label: 'Specific Shares',
         value: 'separate'
       })
      return options
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}${this.typeLocation}`,
        formPath: `data.${this.location}.details.gifts.${this.index}.${this.typeLocation}`,
        jsonSaveField: this.typeLocation
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
