<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors, ...errorsPost]"
                :question="question"
                :sub-section="true"

                :valid="!!data.length">

    <!-- Selected People -->
    <div class="accordion sub-items" >
      <transition-group name="fade">
        <ObjectCard v-for="(person, i) in data"
                    :id="db.saveField + i"
                    :key="db.saveField + i"
                    :ref="db.saveField + i"
                    :select-mode="true"
                    :selected="true"
                    :title="cardTitle(person)"
                    :value="data[i]"
                    :show-delete="false"
                    :index="i"
                    type="recipientsData"
                    :loading="loading"
                    @click="deselectConfirm(person, 'Remove person from gift recipients?', index && index >= 0)"
                    @delete="deletePerson(person.id)"
                    @save="savePerson( null, db.saveField + i)">

          <PersonWill v-if="person.type === 'person'"
                      v-model="data[i]"
                      :key="'recipients-form-data' + i"
                      :errors-post="errorsPatch"
                      :no-save="true"
                      :objectId="person.id"
                      @save="savePersonForm($event, person)"
          />

          <GroupWill
              v-if="person.type === 'group'"
              v-model="data[i]"
              :key="'recipients-form-data' + i"
              @save="savePersonForm($event, person)"/>

          <CharityWill
              v-if="person.type === 'charity'"
              v-model="data[i]"
              :key="'recipients-form-data' + i"
              @save="savePersonForm($event, person)"
          />

        </ObjectCard>
      </transition-group>
    </div>
    <!-- New People -->
    <transition name="fade">
        <b-button
            class="btn-question w-100 mb-20"
            @click="show.addPerson=true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i>Add Recipient
        </b-button>
    </transition>

    <WillPeopleModal
        v-model="show.addPerson"
        :options="optionsData"
        :dataSelected="data"
        :show-charities="true"
        :show-groups="true"
        :noSave="noSave"
        title="What type of recipient would you like to add?"
        sub-title="A single person or multiple people can be selected. You can also add groups of people such as
        'Grandchildren', if more grandchildren were born in the future they would not be excluded."
        @dataSelectedUpdate="data=$event"
        @save="save"
    />

  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../../QuestionBase";
import ObjectCard from "../../../objects/ObjectCard";
import PersonWill from "../../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../../helpers/WillPeopleModal";
import GroupWill from "../../../../../fieldsets/sub/people/GroupWill";
import CharityWill from "../../../../../fieldsets/sub/people/CharityWill";

export default {
  name: 'Recipients',
  components: {
    CharityWill,
    GroupWill,
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    optionsData: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    location: {
      type: String,
      required: true
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Recipients',
        subTitle: 'You can add a single recipient or multiple recipients. You can also add groups of people or charities.',
        tip: null
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}${this.index}Recipients`,
        formPath: `data.${this.location}.details.gifts.${this.index}.recipients`,
        jsonSaveField: 'recipients',
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Backup Executor, click to select'
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
