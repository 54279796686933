// hardcoded for saving gifts

import {httpQuestionnaire} from "@/services";
import {clone} from "@/mixins/clone";
import {scrollTo} from "@/mixins/scrollTo";

export const giftSaveHelpers = {
    name: 'giftSaveHelpers',
    mixins: [clone, scrollTo],
    methods: {
        saveGift(object, index, ref, form=null, callBackFunction = null) {
            if (object && index === null) this.post(object, ref, form, callBackFunction)
            else if (object && index >= 0) this.patch(object, index, ref, callBackFunction)
            else if (!object && ref) this.closeCard(ref, index, callBackFunction)
        },
        post(object, ref, form, callbackFunction) {
            this.clearGiftErrors()

            let data = {
                value: object,
                path: this.db.formPath,
                save_field: this.db.jsonSaveField,
                // access_code: this.$store.getters.accessCode,
                form_type: form
            }

            httpQuestionnaire.post(this.db.saveLocation, data).then(
                () => {
                    // reset form
                    this.data.push(object)
                    this.form = null
                    this.giftsMainInput = null

                    if (this.jsErrors) this.jsErrors = []

                    // callback run
                    if (callbackFunction) callbackFunction()

                    // collapse item
                    this.$nextTick(() => {
                        if (ref) this.closeCard(ref)
                    })

                    this.$emit('clearAdditionalGiftYN')

                }
            ).catch(
                error => {
                    this.handleGiftErrors(error.response.data, 'errorsPost', ref)
                }
            )
        },
        patch(object, index, ref = null, callBackFunction = null) {
            this.clearGiftErrors()
            let data = {
                value: object,
                path: `${this.db.formPath}.${index}`,
                save_field: this.db.jsonSaveField
                // access_code: this.$store.getters.accessCode
            }
            httpQuestionnaire.patch(this.db.saveLocation, data).then(
                () => {
                    // collapse item
                    if (ref) this.closeCard(ref)
                    if (callBackFunction) callBackFunction()
                }
            ).catch(
                error => {
                    this.handleGiftErrors(error.response.data, 'errorsPost', ref)
                }
            )
        },
        deleteGiftConfirm (index) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to remove this gift?',
                {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    cancelVariant: 'outline-primary',
                    okVariant: 'secondary',
                    okTitle: 'Confirm',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).
            then((confirmed) => {
                if (confirmed) {
                    this.deleteGift(index)
                }
            }).
            catch(() => {
                // An error occurred
            });
        },
        deleteGift(index) {
            console.log('delete gift', index)
            this.clearGiftErrors()
            this.data.splice(index, 1)
            // save everything
            if (index) {
                console.log('gift has index')
                this.save()
            } else {
                console.log('gift does not have index')
            }

        },
        clearGiftErrors() {
            this.errorsPost = {}
            this.errorsPatch = {}
        },
        handleGiftErrors(errors, errorsLocation, ref) {
            console.log('handle gift errors', errors, errorsLocation)
            let keys = Object.keys(errors)
            // if multiple forms with multiple errors of the same field then this will scroll to the first form
            // which may not be the desired behaviour
            let id = `#${keys[0]}${this.db.objectId ? this.db.objectId : ''}`
            console.log('id scroll to', id, ref, keys, errors)
            console.log('scrool to here ', `#${ref}${keys[0]}`)
            this.scrollTo(`#${ref}${keys[0]}`)
            this[errorsLocation] = errors
        },
        closeCard(ref, callbackFunction = null) {
            if (ref in this.$refs) this.$refs[ref][0].show = false
            this.scrollTo(`#${ref}`)
            if (callbackFunction) callbackFunction()
        }
    }
}
