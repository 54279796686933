export const willGiftsHelpers = {
    data () {
        return {
            dbLocationConvert: {
                // used to convert save location for first and second death gifts
                giftsFirst: ['dbFirst', 'dataFirst'],
                giftsSecond: ['dbSecond', 'dataSecond']
            }
        }
    },
    mounted() {
        // for old created will insert new fields into data
        // gifts first
        let attributeAddedFirst = false
        if (!('giftsYN' in this.detailsFirstGifts)) {
            // set new fields for old created wills
            this.$set(this.detailsFirstGifts, 'giftsYN', null)
            attributeAddedFirst = true
        }
        if (!('additionalGiftYN' in this.detailsFirstGifts)) {
            // set new fields for old created wills
            this.$set(this.detailsFirstGifts, 'additionalGiftYN', null)
            attributeAddedFirst = true
        }
        if (attributeAddedFirst) {
            let convert = this.dbLocationConvert.giftsFirst
            this.save(convert[0], convert[1])
        }

        // gifts second
        let attributeAddedSecond = false
        if (!('giftsYN' in this.detailsSecondGifts)) {
            // set new fields for old created wills
            this.$set(this.detailsSecondGifts, 'giftsYN', null)
            attributeAddedSecond = true
        }
        if (!('additionalGiftYN' in this.detailsSecondGifts)) {
            // set new fields for old created wills
            this.$set(this.detailsSecondGifts, 'additionalGiftYN', null)
            attributeAddedSecond = true
        }
        if (attributeAddedSecond) {
            let convert = this.dbLocationConvert.giftsSecond
            this.save(convert[0], convert[1])
        }

    },
    computed: {
        detailsFirstGifts() {
            return this.form.data.giftsFirst.details
        },
        detailsSecondGifts() {
            return this.form.data.giftsSecond.details
        },
        // needed for saving
        dataFirst () {
            return this.form.data.giftsFirst.details
        },
        dataSecond () {
            return this.form.data.giftsSecond.details
        }
    },
    methods: {
        setNewGiftBlank (location) {
            this.form.data[location].details.additionalGiftYN=null
            this.$nextTick(() => {
                this.$refs.firstAdditionalGifts.$refs.input.save()
            })
        },
        resetFirstGifts() {
            if (this.form.data.giftsFirst.details.giftsYN !== false) return
            this.form.data.giftsFirst.details.gifts = []
            this.$nextTick(() => {
                let convert = this.dbLocationConvert.giftsFirst
                this.save(convert[0], convert[1])
            })
        },
        resetSecondGifts() {
            if (this.form.data.giftsSecond.details.giftsYN !== false) return
            this.form.data.giftsSecond.details.gifts = []
            this.$nextTick(() => {
                let convert = this.dbLocationConvert.giftsSecond
                this.save(convert[0], convert[1])
            })
        }
    }
}
